import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import authReducer, { checkAuthentication } from 'store/auth/slices';
import {
  accessTokenUpdateWasTriggeredFromSseSelector,
  isAuthenticationCheckingSelector,
  isAuthorizedSelector,
  userRolesSelector
} from 'store/auth/selectors';
import { getFileUploadingStatus } from 'store/dashboard/slices';
import authSaga from 'store/auth/saga';
import dashboardReducer from 'store/dashboard/slices';
import dashboardSaga from 'store/dashboard/saga';

import { Helmet } from 'react-helmet';
import iclLogo from 'assets/images/icl-logo.svg';
import Snackbar from 'components/@extended/Snackbar';
import { isAdmin } from 'utils/common';

const App = () => {
  useInjectReducer({ key: 'auth', reducer: authReducer });
  useInjectSaga({ key: 'auth', saga: authSaga });

  useInjectReducer({ key: 'dashboard', reducer: dashboardReducer });
  useInjectSaga({ key: 'dashboard', saga: dashboardSaga });

  const isAuthorized = useSelector(isAuthorizedSelector);
  const isAuthenticationChecking = useSelector(isAuthenticationCheckingSelector);
  const accessTokenUpdateWasTriggeredFromSse = useSelector(accessTokenUpdateWasTriggeredFromSseSelector);
  const roles = useSelector(userRolesSelector);
  const adminRole = isAdmin(roles);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/oauth/code') {
      dispatch(checkAuthentication());
    }
  }, []);

  useEffect(() => {
    if (isAuthorized && adminRole && !isAuthenticationChecking && !accessTokenUpdateWasTriggeredFromSse) {
      dispatch(getFileUploadingStatus());
    }
  }, [isAuthorized, adminRole, isAuthenticationChecking, accessTokenUpdateWasTriggeredFromSse]);

  return (
    <ThemeCustomization>
      <Helmet>
        <title>BI Pharma</title>
        <link rel="icon" href={iclLogo} />
      </Helmet>
      <Locales>
        <ScrollTop>
          <>
            <Routes />
            <Snackbar />
          </>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
