import { call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import {
  loginGetTokens,
  loginGetTokensRequest,
  loginGetTokensSuccess,
  loginGetTokensFailure,
  logout,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  updateAccessToken,
  updateAccessTokenSuccess,
  updateAccessTokenFailure,
  updateAccessTokenRequest,
  checkAuthentication,
  checkAuthenticationRequest,
  checkAuthenticationFailure,
  checkAuthenticationSuccess
} from './slices';
import AuthService from '_api/auth-service';
import { removeFromLocalStorage, saveInLocalStorage } from 'store/utils/auth-helpers';
import { closeFileUploadingStatusConnection } from 'store/dashboard/slices';
import { getKeycloakLoginUrl, getKeycloakLogoutUrl } from 'config';

function* loginGetTokensHandler({ payload }) {
  const { code } = payload;
  yield put(loginGetTokensRequest());
  try {
    const response: SagaReturnType<typeof AuthService.loginGetTokens> = yield call(AuthService.loginGetTokens, code);
    if (response && response.status === 200) {
      yield call(
        saveInLocalStorage,
        response.data.access_token,
        String(response.data.expires_in),
        response.data.refresh_token,
        String(response.data.refresh_expires_in)
      );
      yield put(loginGetTokensSuccess(response.data));
    } else {
      yield put(loginGetTokensFailure('Login get tokens error'));
    }
  } catch (err) {
    yield put(loginGetTokensFailure(err?.response?.data?.localizedMessage));
  }
}

function* logoutHandler() {
  yield put(logoutRequest());
  try {
    yield call(removeFromLocalStorage);
    yield put(closeFileUploadingStatusConnection());
    yield put(logoutSuccess());
    window.location.href = getKeycloakLogoutUrl(window.location.origin);
  } catch (err) {
    yield put(logoutFailure());
  }
}

export function* checkAuthenticationHandler() {
  yield put(checkAuthenticationRequest());
  try {
    const access_token = localStorage.getItem('access_token');
    const access_expires_in = localStorage.getItem('access_expires_in'); // seconds

    const refresh_token = localStorage.getItem('refresh_token');
    const refresh_expires_in = localStorage.getItem('refresh_expires_in'); // seconds

    const tokens_received_time = localStorage.getItem('tokens_received_time');

    const currentTime = new Date().getTime();

    let isRefreshTokenExpired = false;
    if (refresh_token && refresh_expires_in && tokens_received_time) {
      isRefreshTokenExpired = Number(tokens_received_time) + Number(refresh_expires_in) * 1000 <= currentTime;
    }

    if (!refresh_token || isRefreshTokenExpired) {
      yield put(checkAuthenticationFailure());
      yield call(removeFromLocalStorage);
      window.location.href = getKeycloakLoginUrl(window.location.origin);
      return;
    }

    let isAccessTokenExpired = false;
    if (access_token && access_expires_in && tokens_received_time) {
      isAccessTokenExpired = Number(tokens_received_time) + Number(access_expires_in) * 1000 <= currentTime;
    }

    if (!access_token || !access_expires_in || !refresh_expires_in || !tokens_received_time || isAccessTokenExpired) {
      return yield put(updateAccessToken(false));
    }
    yield put(checkAuthenticationSuccess());
  } catch (err) {
    yield put(checkAuthenticationFailure());
    yield call(removeFromLocalStorage);
  }
}

export function* updateAccessTokenHandler() {
  yield put(updateAccessTokenRequest());
  try {
    const response: SagaReturnType<typeof AuthService.updateAccessToken> = yield call(AuthService.updateAccessToken);
    if (response && response.status === 200) {
      yield call(
        saveInLocalStorage,
        response.data.access_token,
        String(response.data.expires_in),
        response.data.refresh_token,
        String(response.data.refresh_expires_in)
      );
      return yield put(updateAccessTokenSuccess(response.data));
    } else {
      yield put(updateAccessTokenFailure('Update access token error'));
    }
  } catch (err) {
    if (err?.response?.status === 400 || err?.response?.status === 401) {
      yield call(removeFromLocalStorage);
    }
    yield put(updateAccessTokenFailure(err?.response?.data?.localizedMessage));
    yield put(checkAuthentication());
  }
}

export default function* () {
  yield takeLatest(loginGetTokens, loginGetTokensHandler);
  yield takeLatest(logout, logoutHandler);
  yield takeLatest(checkAuthentication, checkAuthenticationHandler);
  yield takeLatest(updateAccessToken, updateAccessTokenHandler);
}
