import { DefaultConfigProps } from 'types/config';
import addUserIcon from 'assets/images/faq/add-user.png';
import addUserFormIcon from 'assets/images/faq/add-user-form.png';
import addUserSuccessIcon from 'assets/images/faq/add-user-success.png';
import editUserIcon from 'assets/images/faq/edit-user.png';
import editUserFormIcon from 'assets/images/faq/edit-user-form.png';
import changeUserActiveIcon from 'assets/images/faq/change-user-active.png';
import uploadFileIcon from 'assets/images/faq/upload-file.png';
import uploadFileFormIcon from 'assets/images/faq/upload-file-form.png';
import uploadFileSuccessIcon from 'assets/images/faq/upload-file-success.png';
import allFiltersIcon from 'assets/images/faq/all-filters.png';
import highlightedFiltersIcon from 'assets/images/faq/highlighted-filters.png';
import appliedFiltersIcon from 'assets/images/faq/applied-filters.png';
import redrawnSelectedFiltersIcon from 'assets/images/faq/redrawn-selected-filters.png';
import { Faq, FaqBlock, FaqBlockType } from 'types/faq';
import { TableRowType } from 'types/table';

export const drawerWidth = 256;

// max size - 5 GB (1 GB - 1073741824):
export const uploadFileMaxSize = 5368709120;

export const APP_CONFIG = {
  URL: {
    BASE_KEYCLOAK: process.env.REACT_APP_REST_BASE_URL_KEYCLOAK,
    BASE_AUTH: process.env.REACT_APP_REST_BASE_URL_AUTH,
    BASE_PROCESSING: process.env.REACT_APP_REST_BASE_URL_PROCESSING,
    BASE_SUPERSET: process.env.REACT_APP_REST_BASE_URL_SUPERSET,
    BASE_SUPERSET_PORT: process.env.REACT_APP_REST_BASE_PORT_SUPERSET
  }
};

export const getKeycloakRedirectUri = (origin: string) => `${origin}/oauth/code`;

export const getKeycloakLoginUrl = (origin: string) =>
  `${APP_CONFIG.URL.BASE_KEYCLOAK}/realms/ICL_BI_Pharma/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&response_type=${process.env.REACT_APP_KEYCLOAK_RESPONSE_TYPE}&redirect_uri=${origin}/oauth/code`;
export const getKeycloakLogoutUrl = (origin: string) =>
  `${APP_CONFIG.URL.BASE_KEYCLOAK}/realms/ICL_BI_Pharma/protocol/openid-connect/logout?post_logout_redirect_uri=${origin}/&client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`;
export const KEYCLOAK_TOKEN_URL = `/realms/ICL_BI_Pharma/protocol/openid-connect/token`;

export const API_BASE_URL = `${APP_CONFIG.URL.BASE_AUTH}`;
export const API_KEYCLOAK_BASE_URL = `${APP_CONFIG.URL.BASE_KEYCLOAK}`;
export const API_UPLOAD_FILES_BASE_URL = `${APP_CONFIG.URL.BASE_PROCESSING}`;
export const API_SUPERSET_BASE_URL = `${APP_CONFIG.URL.BASE_SUPERSET}`;
export const API_SUPERSET_BASE_PORT = `${APP_CONFIG.URL.BASE_SUPERSET_PORT}`;

export enum KEYCLOAK_GRANT_TYPE {
  AuthorizationCode = 'authorization_code',
  RefreshToken = 'refresh_token'
}

export const TASK_INIT_ERROR_MESSAGE = 'INIT STATUS';

export const faqDashboardTableRows: TableRowType[] = [
  {
    rowId: 1,
    cells: [
      {
        cellId: 11,
        text: 'Объем выбытий, YTD, MTD',
        rowSpan: 5
      },
      {
        cellId: 12,
        text: 'YTD'
      },
      {
        cellId: 13,
        simpleList: true,
        items: [
          {
            cellId: 131,
            text: 'Отображает значение Объема продаж YTD.'
          },
          {
            cellId: 132,
            text: 'Спарклайн отображает динамику по показателю с начала текущего года.'
          }
        ]
      },
      {
        cellId: 14,
        items: [
          {
            cellId: 141,
            text: 'Препарат'
          },
          {
            cellId: 142,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 2,
    cells: [
      {
        cellId: 21,
        text: 'MTD'
      },
      {
        cellId: 22,
        simpleList: true,
        items: [
          {
            cellId: 221,
            text: 'Отображает значение Объема продаж MTD.'
          },
          {
            cellId: 222,
            text: 'Спарклайн отображает динамику по показателю с начала текущего месяца.'
          }
        ]
      },
      {
        cellId: 23,
        items: [
          {
            cellId: 231,
            text: 'Препарат'
          },
          {
            cellId: 232,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 3,
    cells: [
      {
        cellId: 31,
        text: 'Объем продаж, YTD vs Объем продаж (Plan), YTD (Plan)'
      },
      {
        cellId: 32,
        simpleList: true,
        items: [
          {
            cellId: 321,
            text: 'Визуализирует на одном графике сравнение 2 пар показателей за текущий год:'
          },
          {
            cellId: 322,
            items: [
              {
                cellId: 3221,
                text: 'Объем продаж (факт) vs плановое значение*'
              },
              {
                cellId: 3222,
                text: 'Объем продаж (YTD) vs плановое значение*'
              }
            ]
          },
          {
            cellId: 323,
            text: '*Плановые значения сгенерированы автоматически и представлены в качестве примера. Расчет/загрузка плановых значений доступны в полной версии продукта.'
          }
        ]
      },
      {
        cellId: 33,
        items: [
          {
            cellId: 331,
            text: 'Препарат'
          },
          {
            cellId: 332,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 4,
    cells: [
      {
        cellId: 41,
        text: 'Детали'
      },
      {
        cellId: 42,
        text: 'Представление отчета "Объем продаж, YTD vs Объем продаж (Plan), YTD (Plan)" в табличном виде'
      },
      {
        cellId: 43,
        items: [
          {
            cellId: 431,
            text: 'Препарат'
          },
          {
            cellId: 432,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 5,
    cells: [
      {
        cellId: 51,
        text: 'Объемы по типам выбытия'
      },
      {
        cellId: 52,
        text: 'Круговая диаграмма с данными по объемам выбытия в разрезе по типу выбытия. Отображает фактические значения и % от общего объема по выбытию.'
      },
      {
        cellId: 53,
        items: [
          {
            cellId: 531,
            text: 'Препарат'
          },
          {
            cellId: 532,
            text: 'Субъект РФ'
          },
          {
            cellId: 533,
            text: 'Период'
          }
        ]
      }
    ]
  },
  {
    rowId: 6,
    cells: [
      {
        cellId: 61,
        text: 'YTD, MTD vs Last Year',
        rowSpan: 4
      },
      {
        cellId: 62,
        text: 'YTD vs YTD_LY по субъектам РФ'
      },
      {
        cellId: 63,
        text: 'Отображает фактические значения по Объему продаж YTD по субъектам РФ за текущий и прошлый годы, а также изменение значения показателя в %.'
      },
      {
        cellId: 64,
        items: [
          {
            cellId: 641,
            text: 'Препарат'
          },
          {
            cellId: 642,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 7,
    cells: [
      {
        cellId: 71,
        text: 'YTD vs YTD_LY по препаратам'
      },
      {
        cellId: 72,
        text: 'Отображает фактические значения по Объему продаж YTD по препаратам за текущий и прошлый годы, а также изменение значения показателя в %.'
      },
      {
        cellId: 73,
        items: [
          {
            cellId: 731,
            text: 'Препарат'
          },
          {
            cellId: 732,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 8,
    cells: [
      {
        cellId: 81,
        text: 'MTD vs MTD_LY по субъектам РФ'
      },
      {
        cellId: 82,
        text: 'Отображает фактические значения по Объему продаж MTD по субъектам РФ за текущий месяц и аналогичный месяц прошлого года, а также изменение значения показателя в %.'
      },
      {
        cellId: 83,
        items: [
          {
            cellId: 831,
            text: 'Препарат'
          },
          {
            cellId: 832,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 9,
    cells: [
      {
        cellId: 91,
        text: 'MTD vs MTD_LY по препаратам'
      },
      {
        cellId: 92,
        text: 'Отображает фактические значения по Объему продаж YTD по препаратам за текущий месяц и аналогичный месяц прошлого года, а также изменение значения показателя в %.'
      },
      {
        cellId: 93,
        items: [
          {
            cellId: 931,
            text: 'Препарат'
          },
          {
            cellId: 932,
            text: 'Субъект РФ'
          }
        ]
      }
    ]
  },
  {
    rowId: 10,
    cells: [
      {
        cellId: 101,
        text: 'Объем продаж по субъектам РФ'
      },
      {
        cellId: 102,
        text: 'Объем продаж по субъектам РФ'
      },
      {
        cellId: 103,
        simpleList: true,
        items: [
          {
            cellId: 1031,
            text: 'Географическая карта РФ с отображением данных по объему продаж при наведении курсора мыши на субъект РФ. Интенсивность тона цветовой схемы зависит от значения объема продаж в конкретном субъекте РФ.'
          },
          {
            cellId: 1032,
            text: 'Для увеличения масштаба необходимо кликнуть мышью по необходимой области геокарты, зажав CTRL. Для возврата исходного масштаба необходимо кликнуть мышью по любой области вне геокарты, зажав CTRL.'
          }
        ]
      },
      {
        cellId: 104,
        items: [
          {
            cellId: 1041,
            text: 'Препарат'
          },
          {
            cellId: 1042,
            text: 'Субъект РФ'
          },
          {
            cellId: 1043,
            text: 'Год'
          },
          {
            cellId: 1044,
            text: 'Месяц'
          }
        ]
      }
    ]
  },
  {
    rowId: 11,
    cells: [
      {
        cellId: 111,
        text: 'Средние продажи по субъектам РФ'
      },
      {
        cellId: 112,
        text: 'Средние продажи по субъектам РФ'
      },
      {
        cellId: 113,
        simpleList: true,
        items: [
          {
            cellId: 1131,
            text: 'Отображает значение Средних продаж по субъектам РФ, рассчитанное как среднее значение объема продаж за 3 последних закрытых месяца (Rolling Quarter или R QRT).'
          },
          {
            cellId: 1132,
            text: 'Столбец R QRT отображает Средние продажи для последнего закрытого (завершившегося) месяца.'
          },
          {
            cellId: 1133,
            isCircleType: true,
            items: [
              {
                cellId: 11331,
                text: 'Например, текущий период - март 2024. Значение в столбце R QRT - средние продажи за Февраль 2024, рассчитанное как среднее значение объема продаж за февраль 2024, январь 2024, декабрь 2023.'
              }
            ]
          },
          {
            cellId: 1134,
            text: 'Спарклайн отображает динамику по показателю в разрезе по месяцам с начала прошлого года по последний закрытый месяц текущего года.'
          }
        ]
      },
      {
        cellId: 114,
        items: [
          {
            cellId: 1141,
            text: 'Препарат'
          },
          {
            cellId: 1142,
            text: 'Субъект РФ'
          },
          {
            cellId: 1143,
            text: 'Аптека'
          }
        ]
      }
    ]
  },
  {
    rowId: 12,
    cells: [
      {
        cellId: 121,
        text: 'Средние продажи по аптекам'
      },
      {
        cellId: 122,
        text: 'Средние продажи по аптекам'
      },
      {
        cellId: 123,
        simpleList: true,
        items: [
          {
            cellId: 1231,
            text: 'Данные по средним продажам в разрезе по аптекам, рассчитанное как среднее значение объема продаж за 3 последних закрытых месяца (Rolling Quarter или R QRT).'
          },
          {
            cellId: 1232,
            text: 'По умолчанию отображает значение Средних продаж для месяца, выбранного в фильтре "Период (R QRT)" в подразделе "Средние продажи" панели Filters.'
          },
          {
            cellId: 1233,
            isCircleType: true,
            items: [
              {
                cellId: 12331,
                text: 'Например, выбрано значение в фильтре Период (R QRT) = 2024 February. Значение в столбце "Средние продажи, уп" отобразит Средние продажи за Февраль 2024, рассчитанное как среднее значение объема продаж за февраль 2024, январь 2024, декабрь 2023.'
              }
            ]
          },
          {
            cellId: 1234,
            text: 'Отчет имеет возможность поиска аптеки по ее названию или адресу в поле Search.'
          }
        ]
      },
      {
        cellId: 124,
        items: [
          {
            cellId: 1241,
            text: 'Препарат'
          },
          {
            cellId: 1242,
            text: 'Субъект РФ'
          },
          {
            cellId: 1243,
            text: 'Аптека'
          }
        ]
      }
    ]
  }
];

export const faqFiltersTableRows: TableRowType[] = [
  {
    rowId: 1,
    cells: [
      {
        cellId: 11,
        text: 'Продажи',
        rowSpan: 5
      },
      {
        cellId: 12,
        text: 'Препарат'
      },
      {
        cellId: 13,
        text: 'Все отчеты (виджеты)'
      }
    ]
  },
  {
    rowId: 2,
    cells: [
      {
        cellId: 21,
        text: 'Субъект РФ'
      },
      {
        cellId: 22,
        text: 'Все отчеты (виджеты)'
      }
    ]
  },
  {
    rowId: 3,
    cells: [
      {
        cellId: 31,
        text: 'Период'
      },
      {
        cellId: 32,
        items: [
          {
            cellId: 321,
            text: 'Круговая диаграмма "Объемы по типам выбытия"'
          },
          {
            cellId: 322,
            text: 'Геокарта "Объем продаж по субъектам РФ"'
          }
        ]
      }
    ]
  },
  {
    rowId: 4,
    cells: [
      {
        cellId: 41,
        text: 'Аптека'
      },
      {
        cellId: 42,
        simpleList: true,
        items: [
          {
            cellId: 421,
            text: 'Таблицы'
          },
          {
            cellId: 422,
            items: [
              {
                cellId: 4221,
                text: '"Средние продажи по субъектам РФ"'
              },
              {
                cellId: 4222,
                text: '"Средние продажи по аптекам"'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    rowId: 5,
    cells: [
      {
        cellId: 51,
        text: 'Период (R QRT)'
      },
      {
        cellId: 52,
        text: 'Таблица "Средние продажи по аптекам"'
      }
    ]
  },
  {
    rowId: 6,
    cells: [
      {
        cellId: 61,
        text: 'Прочие',
        rowSpan: 3
      },
      {
        cellId: 62,
        text: 'Субъект РФ'
      },
      {
        cellId: 63,
        text: 'Все отчеты (виджеты)'
      }
    ]
  },
  {
    rowId: 7,
    cells: [
      {
        cellId: 71,
        text: 'Год'
      },
      {
        cellId: 72,
        text: 'Все отчеты (виджеты)'
      }
    ]
  },
  {
    rowId: 8,
    cells: [
      {
        cellId: 81,
        text: 'Препарат'
      },
      {
        cellId: 82,
        text: 'Все отчеты (виджеты)'
      }
    ]
  }
];

const ContactsBlock: FaqBlock = {
  id: 2,
  type: FaqBlockType.LIST,
  list: [
    {
      listId: 1,
      items: [
        {
          simpleList: true,
          listId: 11,
          items: [
            {
              listId: 111,
              oneLineTextList: true,
              text: 'почта - '
            },
            {
              listId: 112,
              oneLineTextList: true,
              text: 'bi-pharma@icl-soft.ru',
              isLink: true,
              isMail: true
            }
          ]
        },
        {
          listId: 12,
          simpleList: true,
          items: [
            {
              listId: 121,
              oneLineTextList: true,
              text: 'ссылка на ТГ чат - '
            },
            {
              listId: 122,
              oneLineTextList: true,
              text: 'https://t.me/+EmIjJqwRZhs0YWMy',
              isLink: true
            }
          ]
        }
      ]
    }
  ]
};

export const faqAccordions: Faq[] = [
  {
    id: 1,
    title: '1. Столкнулся с проблемой в работе приложения / хочу дать обратную связь / у меня есть вопрос…',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Мы на связи по контактам ниже:'
          }
        ]
      },
      ContactsBlock
    ]
  },
  {
    id: 2,
    title: '2. Как добавить нового пользователя?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Во вкладке "Пользователи" нажать на кнопку "Добавить"'
          }
        ]
      },
      {
        id: 2,
        type: FaqBlockType.IMAGE,
        src: addUserIcon,
        alt: 'Add User icon'
      },
      {
        id: 3,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Заполнить поля в открывшейся форме и выбрать группу с необходимой ролью: User или Admin. Нажать кнопку "Создать".'
          }
        ]
      },
      {
        id: 4,
        type: FaqBlockType.IMAGE,
        src: addUserFormIcon,
        alt: 'Add User Form icon',
        maxWidth: '450px'
      },
      {
        id: 5,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Созданный пользователь появится в таблице "Пользователи"'
          }
        ]
      },
      {
        id: 6,
        type: FaqBlockType.IMAGE,
        src: addUserSuccessIcon,
        alt: 'Add User Success icon'
      }
    ]
  },
  {
    id: 3,
    title: '3. Как редактировать пользователей?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title:
              'Через меню действий (кнопка в виде трех точек, расположенная в конце строки для каждого пользователя) нажать на кнопку "Редактировать".'
          }
        ]
      },
      {
        id: 2,
        type: FaqBlockType.IMAGE,
        src: editUserIcon,
        alt: 'Edit User icon'
      },
      {
        id: 3,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'В открывшейся форме внести изменения в нужные поля, нажать "Сохранить".'
          }
        ]
      },
      {
        id: 4,
        type: FaqBlockType.IMAGE,
        src: editUserFormIcon,
        alt: 'Edit User Form icon',
        maxWidth: '500px'
      },
      {
        id: 5,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title:
              'Для активации/деактивации пользователя необходимо нажать на кнопку "Деактивировать"/"Активировать" для соответствующего пользователя.'
          }
        ]
      },
      {
        id: 6,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Статус деактивированного пользователя подсвечивается красным в поле "Активность", активного - зеленым.'
          }
        ]
      },
      {
        id: 7,
        type: FaqBlockType.IMAGE,
        src: changeUserActiveIcon,
        alt: 'Change User Active icon'
      }
    ]
  },
  {
    id: 4,
    title: '4. Что делать, если забыл пароль?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title:
              'Необходимо обратиться к Администратору для восстановления пароля или нажать на кнопку "Забыли пароль?", ввести свой email и нажать на кнопку "Подтвердить".'
          }
        ]
      },
      {
        id: 2,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'На почту придет письмо со ссылкой на восстановление пароля.'
          }
        ]
      }
    ]
  },
  {
    id: 5,
    title: '5. Как обновить данные в Дашбордах / загрузить новый файл?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'На вкладке "Дашборд" нажать кнопку "Загрузить Файл"'
          }
        ]
      },
      {
        id: 2,
        type: FaqBlockType.IMAGE,
        src: uploadFileIcon,
        alt: 'Upload File icon'
      },
      {
        id: 3,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'В открывшееся окно загрузки перетащить файл или выбрать файл через Проводник. Поддерживаемый формат файла - '
          },
          {
            id: 2,
            title: 'CSV.',
            isBold: true
          }
        ]
      },
      {
        id: 4,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Нажать кнопку "Отправить".'
          }
        ]
      },
      {
        id: 5,
        type: FaqBlockType.IMAGE,
        src: uploadFileFormIcon,
        alt: 'Upload File Form icon',
        maxWidth: '500px'
      },
      {
        id: 6,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'После нажатия на кнопку "Отправить" появится прелоадер, информирущий о статусе загрузки файла.'
          }
        ]
      },
      {
        id: 7,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Загрузка и обработка файла могут занимать несколько минут.'
          }
        ]
      },
      {
        id: 8,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'После успешной загрузки файла на странице отобразятся обновленные Дашборды.'
          }
        ]
      },
      {
        id: 9,
        type: FaqBlockType.IMAGE,
        src: uploadFileSuccessIcon,
        alt: 'Upload File Success icon'
      }
    ]
  },
  {
    id: 6,
    title: '6. Как получить файл для загрузки, какой формат загружаемого файла?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.LIST,
        list: [
          {
            listId: 1,
            items: [
              {
                listId: 11,
                simpleList: true,
                items: [
                  {
                    listId: 111,
                    oneLineTextList: true,
                    text: 'Сформировать файл в формате .csv согласно п.11.7.2 "Общий отчет по выбытию ЛП" инструкции '
                  },
                  {
                    listId: 112,
                    oneLineTextList: true,
                    text: 'https://mdlp.crpt.ru/static/document/api_mdlp_ru.pdf',
                    isLink: true
                  }
                ]
              },
              {
                listId: 12,
                simpleList: true,
                items: [
                  {
                    listId: 121,
                    text: 'Задать период выгрузки - конкретный месяц.'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: 2,
        type: FaqBlockType.INFORMATION,
        text: ' В рамках MVP для демонстрации функционала дашбордов производится генерация исторических данных c января 2022 г. по март 2024 г. на основе данных, загруженных пользователем за конкретный месяц.'
      }
    ]
  },
  {
    id: 7,
    title: '7. Какие дашборды/отчеты доступны в приложении?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Пользователям доступны 2 дашборда со следующими отчетами:'
          }
        ]
      },
      {
        id: 2,
        type: FaqBlockType.LIST,
        list: [
          {
            listId: 1,
            items: [
              {
                listId: 11,
                simpleList: true,
                items: [
                  {
                    listId: 111,
                    oneLineTextList: true,
                    text: 'Дашборд '
                  },
                  {
                    listId: 112,
                    oneLineTextList: true,
                    text: '"Продажи"',
                    isItalic: true
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: 3,
        type: FaqBlockType.TABLE,
        rows: faqDashboardTableRows,
        headers: ['Вкладка', 'Название отчета', 'Описание', 'Доступные фильтры'],
        colWidths: ['260px', '230px', '65%', '150px']
      },
      {
        id: 4,
        type: FaqBlockType.LIST,
        list: [
          {
            listId: 1,
            items: [
              {
                listId: 11,
                simpleList: true,
                items: [
                  {
                    listId: 111,
                    oneLineTextList: true,
                    text: 'Дашборд '
                  },
                  {
                    listId: 112,
                    oneLineTextList: true,
                    text: '"Прочие"',
                    isItalic: true
                  },
                  {
                    listId: 113,
                    oneLineTextList: true,
                    text: ' содержит отчеты по объему продаж в корреляции с данными по средней заработной плате и численности населения РФ в разрезе по Году, Препарату и Субъектам РФ:'
                  }
                ]
              },
              {
                listId: 12,
                isCircleType: true,
                oneLineTextList: true,
                items: [
                  {
                    listId: 121,
                    text: 'Соотношение объема продаж и СЗП населения'
                  },
                  {
                    listId: 122,
                    text: 'Соотношение объема продаж и численности населения'
                  }
                ]
              },
              {
                listId: 13,
                simpleList: true,
                oneLineTextList: true,
                items: [
                  {
                    listId: 131,
                    text: 'В Дашборде предустановлены значения в фильтрах:'
                  }
                ]
              },
              {
                listId: 14,
                isCircleType: true,
                oneLineTextList: true,
                items: [
                  {
                    listId: 141,
                    text: 'Субъект РФ = Москва'
                  },
                  {
                    listId: 142,
                    text: 'Год = 2022, 2023, 2024'
                  },
                  {
                    listId: 143,
                    text: 'Препарат = Препарат_10, Препарат_15, Препарат_17'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: 5,
        type: FaqBlockType.INFORMATION,
        text: ' В рамках MVP для демонстрации функционала дашбордов производится генерация исторических данных января 2022 г. по март 2024 г. на основе данных, загруженных пользователем за конкретный месяц.'
      }
    ]
  },
  {
    id: 8,
    title: '8. Какой функционал для работы с отчетами есть в приложении?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Панель Filters',
            isBold: true
          }
        ]
      },
      {
        id: 2,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'На странице дашборда имеется панель Filters с набором элементов, которые позволяют фильтровать данные на дашборде.'
          }
        ]
      },
      {
        id: 3,
        type: FaqBlockType.IMAGE,
        src: allFiltersIcon,
        alt: 'All filters icon'
      },
      {
        id: 4,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title:
              'При наведении мыши на поле с выпадающим списком фильтра (пример на скриншоте ниже - "Период") голубой рамкой подсвечиваются те виджеты, к которым данный фильтр применим.'
          }
        ]
      },
      {
        id: 5,
        type: FaqBlockType.IMAGE,
        src: highlightedFiltersIcon,
        alt: 'Highlighted Filters icon'
      },
      {
        id: 6,
        type: FaqBlockType.TABLE,
        rows: faqFiltersTableRows,
        headers: ['Дашборд', 'Элемент панели Filters', 'Применимость'],
        colWidths: ['16%', '27%', '57%'],
        maxWidth: '667px'
      },
      {
        id: 7,
        type: FaqBlockType.LIST,
        list: [
          {
            listId: 1,
            items: [
              {
                listId: 11,
                text: 'Для применения фильтрации необходимо выбрать одно или несколько значений (если поле позволяет выбрать несколько значений) в полях фильтра и нажать кнопку "APPLY FILTERS".'
              },
              {
                listId: 12,
                simpleList: true,
                oneLineTextList: true,
                items: [
                  {
                    listId: 121,
                    text: 'При успешном применении фильтра виджеты дашборда, для которых применим данный фильтр, будут перерисованы в соответствии с выбранными значениями.'
                  }
                ]
              },
              {
                listId: 13,
                text: 'Для сброса фильтров необходимо нажать на крест рядом со значениями в полях фильтра для того, чтобы убрать одно конкретное значение. Для очистки всего поля нажмите на крест в правой части поля фильтра.'
              },
              {
                listId: 14,
                simpleList: true,
                oneLineTextList: true,
                items: [
                  {
                    listId: 141,
                    text: 'Также сбросить все фильтры сразу можно, нажав на кнопку "CLEAR ALL" и затем "APPLY FILTERS" или обновив страницу браузера.'
                  }
                ]
              },
              {
                listId: 15,
                text: 'Увидеть какие фильтры действуют на конкретный виджет на дашборде можно, нажав на иконку в правом верхнем углу нужного виджета. Отобразится меню, в котором перечислены примененные активные фильтры.'
              }
            ]
          }
        ]
      },
      {
        id: 8,
        type: FaqBlockType.IMAGE,
        src: appliedFiltersIcon,
        alt: 'Applied Filters icon'
      },
      {
        id: 9,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Фильтрация данных через элементы (измерения) на виджетах дашборда',
            isBold: true
          }
        ]
      },
      {
        id: 10,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title:
              'При нажатии мышью на конкретный субъект РФ/препарат в таблице или на геокарте виджеты дашборда, для которых применим данный фильтр, будут перерисованы в соответствии с выбранными значениями.'
          }
        ]
      },
      {
        id: 11,
        type: FaqBlockType.IMAGE,
        src: redrawnSelectedFiltersIcon,
        alt: 'Redrawn Selected Filters icon'
      }
    ]
  },
  {
    id: 9,
    title: '9. Могу ли я настроить интерфейс под себя?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        isAlone: true,
        spans: [
          {
            id: 1,
            title: 'На текущий момент такой возможности нет.'
          }
        ]
      }
    ]
  },
  {
    id: 10,
    title: '10. Можно ли добавить отчеты или модифицировать существующие под свои потребности?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        isAlone: true,
        spans: [
          {
            id: 1,
            title: 'На данный момент такой возможности нет, соответствующий функционал появится в будущем.'
          }
        ]
      }
    ]
  },
  {
    id: 11,
    title: '11. Что делать, если на странице не нашел подходящего вопроса/информации?',
    blocks: [
      {
        id: 1,
        type: FaqBlockType.TEXT,
        spans: [
          {
            id: 1,
            title: 'Свяжитесь с нами по контактам ниже:'
          }
        ]
      },
      ContactsBlock
    ]
  }
];

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  defaultPath: '/dashboard',
  fontFamily: `'Raleway', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'themeIcl',
  themeDirection: 'ltr'
};

export default config;
